<template>
  <div id="page-container" class="main-content-boxed">
    <main id="main-container">
      <div class="bg-image">
        <div class="row mx-0 bg-default-op">
          <div
            class="hero-static col-md-6 col-xl-8 d-none d-md-flex align-items-md-end"
          >
            <div class="p-4">
              <p class="fs-3 fw-semibold text-white">
                You can retrieve your password!
              </p>
              <p class="text-white-75 fw-medium">
                Copyright &copy; <span data-toggle="year-copy">2022</span>
              </p>
            </div>
          </div>
          <div
            class="hero-static col-md-6 col-xl-4 d-flex align-items-center bg-body-extra-light"
          >
            <div class="content content-full">
              <div class="px-4 py-2 mb-4">
                <a class="link-fx fw-bold" href="{:url('index/index')}">
                  <i class="fa fa-fire"></i>
                  <span class="fs-4 text-body-color">宥马-Tool</span>
                </a>
                <h1 class="h3 fw-bold mt-4 mb-2">找回账户密码</h1>
                <h2 class="h5 fw-medium text-muted mb-0">请输入你的邮箱</h2>
              </div>
              <form
                class="js-validation-reminder px-4"
                onsubmit="return false;"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    class="form-control"
                    id="mail"
                    v-model="user.email"
                    placeholder="输入你的邮箱"
                  />
                  <label class="form-label">邮箱</label>
                  <div class="text-muted fs-xs">邮箱默认为您的QQ账号邮箱</div>
                </div>
                <div class="row mb-4">
                  <div class="col-6">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="captcha"
                        v-model="user.captcha"
                        placeholder="请输入验证码"
                      />
                      <label class="form-label" for="captcha">验证码</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div>
                      <img
                        class="border"
                        id="captcha_img"
                        :src="base64captcha"
                        @click="getVerify()"
                        alt="captcha"
                        style="height: 58px"
                      />
                    </div>
                  </div>
                </div>

                <div class="mb-4">
                  <button
                    type="submit"
                    class="btn btn-lg btn-alt-primary fw-semibold"
                    @click="Find"
                  >
                    找回密码
                  </button>
                  <div class="mt-4">
                    <a
                      class="fs-sm fw-medium link-fx text-muted me-2 mb-1 d-inline-block"
                      @click="$router.push('/login')"
                    >
                      <i class="fa fa-arrow-left opacity-50 me-1"></i> 返回登录
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getCaptcha, findUser } from "@/api/login";

export default {
  name: "Find",
  data() {
    return {
      user: {},
      base64captcha: "",
    };
  },
  created() {
    this.getVerify();
  },
  methods: {
    getVerify() {
      getCaptcha().then((res) => {
        this.base64captcha = "data:image/jpeg;base64," + res.code;
        this.user.uuid = res.uuid;
      });
    },

    Find() {
      if (this.user.captcha == null || this.user.email == null) {
        this.$message.warning("邮箱或验证码不能为空");

        return false;
      }
      findUser(this.user)
        .then((message) => {
          this.$message.success(message);

          setTimeout(() => {
            this.$router.push("/login");
          }, 1000);
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>

<style scoped>
.bg-image {
  background-image: url("/static/media/photos/photo34@2x.jpg");
}

@media screen and (max-width: 500px) {
  .el-message-box {
    width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-message {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .el-dialog__wrapper .el-dialog {
    width: 300px !important;
  }

  .el-dialog__body {
    padding: 10px 20px !important;
  }

  .el-form-item__label {
    width: 68px !important;
  }

  .el-select,
  .el-input {
    width: 180px !important;
  }
}
</style>
